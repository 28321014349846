import React, { useEffect, useState, useCallback, useRef } from "react"; 
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../hooks/useAuth";
import GeoJsonSvg from "./GeojsontoSvg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SatelliteAltIcon from "@mui/icons-material/SatelliteAlt";
import { Accordion, Divider, Grow, Tooltip,AccordionSummary, AccordionDetails, Skeleton, Button, ListItem, Chip } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { ArrowDropDownIcon, ClearIcon } from "@mui/x-date-pickers";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { GridLoader } from "react-spinners";
import {GeoJSON2SVG} from 'geojson2svg';
import { Bounce, ToastContainer, toast } from "react-toastify";
import ReactImageMagnify from 'react-image-magnify';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip as TP,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Oval } from "react-loader-spinner";
import Alert from "@mui/material/Alert";
import axios from "axios"; 
import * as L from "leaflet";
import { useMap } from "react-leaflet";
import { Grid } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { center as CompCenter } from "@turf/turf";
import LayersIcon from '@mui/icons-material/Layers';

import { ArrowDownward, ArrowRight, ArrowUpward, DirectionsOutlined, ExpandMore, LocalFireDepartmentOutlined, RampLeft } from "@mui/icons-material";
import { Loader } from "../utils/Loader";
import LegendComponent from "../utils/MapLegend";
import { MSAVI } from "../utils/constants";
import { EnlargedImagePosition } from "react-image-magnify/dist/prop-types/EnlargedImage";
import { getContainerStyle, getEnlargedImageContainerStyle } from "react-image-magnify/dist/lib/styles";
import { ENLARGED_IMAGE_POSITION } from "react-image-magnify/dist/constants";
const Farms = ({isVisible, onClose, onGeoJsonClick , setBoundingBox,
    setBounds,
    setCenterCoodinates,
    getStats,
    getViews,
    getViewsData,}) => {

      const getMuiTheme = () =>
        createTheme({
          components: {
            MuiTable: {
              styleOverrides: {
                root: {
                  backgroundColor: "#28282b",
                },
              },
            },
            MUIDataTableHeadCell: {
              styleOverrides: {
                root: {
                  backgroundColor: "#28282b",
                  color: "white",
                  fontWeight: 700,
                },
              },
            },
            MUIDataTableBodyCell: {
              styleOverrides: {
                root: {
                  color: "white",
                  backgroundColor: "#28282b",
                },
              },
            },
            MUIDataTableToolbar: {
              styleOverrides: {
                root: {
                  backgroundColor: "#28282b",
                  color: "white",
                },
              },
            },
            MUIDataTablePagination: {
              styleOverrides: {
                root: {
                  backgroundColor: "#28282b",
                  color: "white",
                },
              },
            },
            MuiIconButton: {
              styleOverrides: {
                root: {
                  color: "white",
                },
              },
            },
          },
        });

        

    const { user } = useAuth();
    const [farmData, setFarmData] = useState([]);
    const [selectedFarm, setSelectedFarm] = useState([]);
    const scrollRef = React.useRef();  

    const [detailedInfo, setDetailedInfo] = useState({});
    const [visibleDetails, setVisibleDetails] = useState({});
    const [loadingDetails, setLoadingDetails] = useState({});
    const [offset, setOffset] = useState(0); // Start with 0
    const [loadingMore, setLoadingMore] = useState(false);
    const [cropperRef, setCropperRef] = useState(null);
    const token = user;
    const [showLoader, setShowLoader] = React.useState(true);
    const [activeIndex, setActiveIndex] = useState(null);  
    const [requestID, setRequestID] = useState(null);  
    const [selectedLevel, setSelectedLevel] = useState(null);  
    const [SENTINELURL, setSENTINELURL] = useState(null);  

    const [selectColor, setSelectedColor] = useState(null);  
    const [requestData, setRequestData] = useState([]);   
    const [chipData, setChipData] = React.useState([
      { key: 0, label: 'NDVI' },
      { key: 1, label: 'MSAVI' },
      { key: 2, label: 'NDRE' },
      { key: 3, label: 'NDMI' },
      { key: 4, label: 'RECI' },
    ]);
    const [selectedChip, setSelectedChip] = useState(0); 
    const [selectedIndex, setSelectedIndex] = useState("NDVI"); 
    const [showPreviewLoader, setShowPreviewLoader] = useState(false); 
    const [showDefaultPreview, setShowDefaultPreview] = useState(true); 
    var omnivore = require("leaflet-omnivore");
    const map = useMap();

    useEffect(() => {

        if (!token) return;

        fetchFarmDetails();

    }, []);

    const scrollUp = () => {
      scrollRef.current.scrollBy({ top: -100, behavior: 'smooth' });  
    };
  
    const scrollDown = () => {
      scrollRef.current.scrollBy({ top: 100, behavior: 'smooth' }); 
    };

  const fetchFarmDetails = useCallback(async () => {
    if (loadingMore) return;

    setLoadingMore(true);
    await axios
      .post(
        "https://escan-systems.com/api/getMyFarm",
        { limit: 10, offset: offset },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {

        const filteredData = response.data.map((item) => ({
          farmDetails: item.farmDetails,
          farmGeoJson: item.farm_geojson,
          id: JSON.parse(item.area).id,
          farmArea: JSON.parse(item.area).area,
          cropData: item.farmyears_data ? JSON.parse(item.farmyears_data).properties?.years_data[0]?.crop_type : 'N/A',
          sowingDate: item.farmyears_data ? JSON.parse(item.farmyears_data).properties?.years_data[0]?.sowing_date : 'N/A',
          name: item.farmyears_data ? JSON.parse(item.farmyears_data).properties?.name : 'N/A',
          center: CompCenter({
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {},
                geometry: JSON.parse(item.farm_geojson).geometry,
              },
            ],
          }).geometry.coordinates[1].toFixed(4)+","+ CompCenter({
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {},
                geometry: JSON.parse(item.farm_geojson).geometry,
              },
            ],
          }).geometry.coordinates[0].toFixed(4)
        }));
        const geojsonData = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {},
              geometry: JSON.parse(filteredData[0].farmGeoJson).geometry,
            },
          ],
        }
      
      
     
      
        setFarmData(filteredData);
        setShowLoader(false);

        // setOffset((prevOffset) => prevOffset + 10);
      })
      .catch((error) => console.error("Error fetching farm details:", error))
      .finally(() => {
        setShowLoader(false);
      });
  }, []);

   
 
    const floatingLayerStyles = {
        position: "absolute",
        top: "-10px",
        right: "0",
        height: "100vh",
        width: "350px",
        zIndex: 1001,
        
        backgroundColor: "#28282B",
    };
    const getCurrentFarmStats = async (farmItem) => {
   

     await axios
     .post(
       "https://escan-systems.com/api/getFarmStats",
       { farmID: farmItem.id, index:"NDVI" },
       {
         headers: {
           "Content-Type": "application/json",
           Authorization: `Bearer ${token}`,
         },
       }
     )
     .then(async (response) => {
      setRequestID(response.data.request_id)
      //getFarmStatsResult

      
      getTaskResult(farmItem.id,response.data.request_id,farmItem)

      console.log("response1",response.data.request_id)
     })
     .catch((error) => console.error("Error fetching farm details:", error))
     .finally(() => {
      setShowPreviewLoader(false)
      setShowDefaultPreview(false)
     });

      console.log("HELLO",farmItem.id)
   
    }
  const getTaskResult = async (farmID, requestID,farmItem) => {

    await axios
      .post(
        "https://escan-systems.com/api/getFarmStatsResult",
        { farmID: farmID, requestID: requestID },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async (response) => {

        console.log("response2", response.data)
        if (response.data.status === "pending") {
          toast(
            "Please wait, Analyzing Field for Statistics",
            {
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "dark",
              transition: Bounce,
            }
          );
          setTimeout(() => {
            getTaskResult(farmID, requestID,farmItem);
          }, 5000);
        } else {
          setRequestData(response.data.result[0])
          console.log(farmItem)
          const geoJson = {
            type: "FeatureCollection",
            features: [
              {

                type: "Feature",
                properties: {},
                geometry: JSON.parse(farmItem.farmGeoJson).geometry,
              },
            ],
          }   
             
          
         

          const geoJsonLayerObject = L.geoJSON(geoJson, {
            style: {
              fillOpacity: 0,
              color: "transparent",
              fillColor: "transparent",
              opacity: 0,
            },
          });
          const layersBounds = geoJsonLayerObject.getBounds();
         // console.log(layersBounds, geoJsonLayerObject.getBounds().toBBoxString())
          // setBoundingBox(layersBounds);
          // setBounds([
          //   [layersBounds._southWest.lat, layersBounds._southWest.lng],
          //   [layersBounds._northEast.lat, layersBounds._northEast.lng],
          // ]);
        const boundryb =
        geoJsonLayerObject.getBounds().toBBoxString().toString().split(",")[0] +
        ";" +
        geoJsonLayerObject.getBounds().toBBoxString().toString().split(",")[2] +
        ";4326/" +
        geoJsonLayerObject.getBounds().toBBoxString().toString().split(",")[1] +
        ";" +
        geoJsonLayerObject.getBounds().toBBoxString().toString().split(",")[3] +
        ";4326";
        const getCropperRef = await getCropper(geoJson)
       
        setCropperRef(getCropperRef.cropper_ref)
        const colorModule = await import(`../utils/constants`);
        const color = colorModule[selectedIndex + "ColorMap"]; 
        const indexLevel = colorModule[selectedIndex + "Level"]; 
        console.log("response3", response.data.result[0],geoJson)
        setSelectedLevel(indexLevel)
        setSelectedColor(color)
       // console.log("COPEr".cropperRef,color,indexLevel,selectedIndex)
        let src=`https://gate.eos.com/api/render/${response.data.result[0].view_id}/${selectedIndex=="MSAVI"?MSAVI:selectedIndex}/13/${boundryb}?TILE_SIZE=400,400&cropper_ref=${getCropperRef.cropper_ref}&CALIBRATE=1&COLORMAP=${color}&MIN_MAX=${indexLevel}&MASKING=CLOUD&MASK_COLOR=cccccc&api_key=apk.e7c0c70a8cf524a8324cf7831d2ac3ff26cb651a61a3fcef28967f1c062ed3de`
       
        setSENTINELURL(src)
         console.log("SRC",SENTINELURL)
          
      }


      })
      .catch((error) => console.error("Error fetching farm details:", error))
      .finally(() => {
        setShowPreviewLoader(false)
        setShowDefaultPreview(false)
      });
  }
    const geoJsonClick = (geoJsonLayer) =>{
        
        
        const geoJsonLayerObject = L.geoJSON(geoJsonLayer, {
          style: {
            fillOpacity: 0,
            color: "transparent",
            fillColor: "transparent",
            opacity: 0,
          },
        });
        const layersBounds = geoJsonLayerObject.getBounds();
        console.log(layersBounds, geoJsonLayerObject.getBounds().toBBoxString())
        setBoundingBox(layersBounds);
        setBounds([
          [layersBounds._southWest.lat, layersBounds._southWest.lng],
          [layersBounds._northEast.lat, layersBounds._northEast.lng],
        ]);
        getStats(geoJsonLayer);
        getViews(
          geoJsonLayer,
          getViewsData.limit,
          getViewsData.page,
          getViewsData.startOfYear,
          getViewsData.tillDate,
          geoJsonLayerObject.getBounds().toBBoxString(),
          true
        );
        onGeoJsonClick()
        const center = layersBounds.getCenter();
        setCenterCoodinates(center);
        map.flyTo(center, 17);
     
    }
    const getCropper = async (layer) => {
      var SendInfo = layer.features[0];
      try {
        const response = await axios.post(
          "https://escan-systems.com/api/getCropper",
          SendInfo,
          {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
          }
        );
        console.log(response.data); // Assuming you want to log the response data
        return response.data;
      } catch (error) {
        console.error("Error:", error);
        // Handle error appropriately
      }
    };
    return (

      <Grow in={isVisible} style={{ transformOrigin: "0 0 0" }}>
      <Card
        sx={{
          backgroundColor: "#28282B",
          position: "absolute",
          top: "30px",
          width: "100%",
          zIndex: 9999999999,
          height: ["220vh", "220vh", "150vh", "150vh", "130vh"],
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#28282B",
          }}
        >
          

          <CardContent
            sx={{
              alignItems: "center",
              alignContent: "center",
              paddingBottom: 0,
              paddingLeft: 0,
            }}
          >
            <IconButton
              style={{ position: "absolute", top: "10px", right: "5px" }}
              onClick={()=>{onClose()}}
            >
              <ClearIcon color="white" style={{ color: "white" }} />
            </IconButton>

            <Typography
              variant="subtitle1"
              color="white"
              component="div"
              style={{ textAlign: "center", fontWeight: "bold" }}
            >
              {"My Fields"}
            </Typography>
          </CardContent>
        </Box>
        {showLoader ? (
          <Box
            style={{
              justifyContent: "start",
              alignItems: "center",
              height: "100%",
            }}
          >

            <Loader />
            {/* <Oval
              visible={true}
              height="50"
              radius="50"
              width="50"
              color="#fff"
              secondaryColor="#cccc"
              ariaLabel="revolving-dot-loading"
              wrapperStyle={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 99999,
              }}
              wrapperClass=""
            /> */}
          </Box>
        ) : (
          <Box
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "row", // Main layout in a row
          }}
        >
          <ThemeProvider theme={getMuiTheme()}>
            {/* Main Grid for splitting the layout */}
            <Grid container sx={{ height: '100vh', width: '100%' }}>
              
              {/* Left Column (Stack of Items) */}
                    <Grid
                      item
                      xs={12} sm={4} md={3} // Adjust size as needed
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '90vh',
                        overflowY: 'hidden',
                        padding: 2,
                        alignItems: 'center',
                      }}
                    >
                      {/* Scroll Up Button */}
                       
                      <Box
                        onClick={scrollUp}
                        sx={{
                          background: '#00000026',
                          color: 'white',
                          width: '100px',
                          borderRadius: '5px',
                          cursor: 'pointer',
                          fontSize: '26px',
                          padding: '3px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          transition: 'transform 0.2s', // Transition for the magnify effect
                          '&:hover': {
                            transform: 'scale(1.2)', // Magnify effect on hover
                          },
                        }}
                      >
                        <ArrowUpwardIcon />
                      </Box>
                     

                      <Box
                        ref={scrollRef} // Attach ref to the Box for scrolling
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '80vh',
                          overflowY: 'scroll', // Enable scrolling within this Box
                          padding: 2,
                          alignItems: 'center',
                         
                        }}
                      >
                        {farmData.map((item, index) => (
                          <Card
                            key={index}
                            onClick={() => {
                              setActiveIndex(index);
                              setShowPreviewLoader(true);
                              setShowDefaultPreview(false);
                              setSelectedFarm(item);
                              getCurrentFarmStats(item);
                            }}
                            sx={{
                              background: '#161616',
                              marginBottom: 5,
                              padding: 2,
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              width: '280px',
                              minHeight: '200px',
                              height: 'auto',
                              borderRadius: 3,
                              border: activeIndex === index ? '2px solid #f9b64d' : 'none',
                              cursor: 'pointer',
                            }}
                          >
                            <Box display="flex" flexDirection="column" alignItems="center" width="100%" height='200px'>
                              <Box flexShrink={1} width={100} height={100} sx={{ marginBottom: 2 }}>
                                <GeoJsonSvg farmGeoJson={JSON.parse(item.farmGeoJson)} h={120} w={120} scaleh={90} scalew={90} />
                              </Box>
                              <Typography variant="body1" sx={{ color: 'white', marginBottom: 1 }}>
                                {item.name}, {item.farmArea} Ha
                              </Typography>
                              <Typography variant="body1" sx={{ color: 'white', marginBottom: 1 }}>
                                Location: {item.center}
                              </Typography>
                            </Box>
                          </Card>
                        ))}
                      </Box>

                      {/* Scroll Down Button */}
                      <Box
                        onClick={scrollDown}
                        sx={{
                          background: '#00000026',
                          color: 'white',
                          width: '100px',
                          marginTop:1,
                          borderRadius: '5px',
                          cursor: 'pointer',
                          fontSize: '26px',
                          padding: '3px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          transition: 'transform 0.2s', // Transition for the magnify effect
                          '&:hover': {
                            transform: 'scale(1.2)', // Magnify effect on hover
                          },
                        }}
                      >
                        <ArrowDownward />
                      </Box>
                    </Grid>

              {/* Right Column (Preview Area) */}
              <Grid
                item
                xs={12} sm={8} md={9} // Adjust size as needed
                sx={{
                  display: 'flex',
                  justifyContent: 'center', 
                  alignItems: 'center',
                  flexDirection:'column',height:'80vh'
                 
                }}
              >
                   {/*  alignItems: !showPreviewLoader?'':'center',
                  flexDirection:'column' */}

                      <Oval
                        visible={showPreviewLoader}
                        height="50"
                        radius="50"
                        width="50"
                        color="#fff"
                        secondaryColor="#cccc"
                        ariaLabel="revolving-dot-loading"
                        wrapperClass=""
                      /> 
                      {showDefaultPreview ? (
                        <>
                          <LayersIcon
                            style={{ color: 'grey', padding: 1, fontSize: 80, marginTop: 20 }}
                          />
                          <Typography
                            variant="body2"
                            color="grey"
                            style={{ fontWeight: "bold", fontSize: "14px", marginTop: 20 }}
                          >
                            Please select any of your fields to view detailed Stats
                          </Typography>

                            
                        </>
                      ) : !showPreviewLoader ? (

                          <>

                            <Box display="flex" flexDirection="column" alignItems="center" width="100%" height='200px' sx={{ marginBottom: 5 }}>

                              <Box width={130} height={130} sx={{ marginBottom: 2 }}>
                                <GeoJsonSvg farmGeoJson={JSON.parse(selectedFarm.farmGeoJson)}  h={170} w={170} scaleh={130} scalew={130}/>
                              </Box>
                              <Typography variant="body1" sx={{ color: 'white', marginBottom: 1 }}> {selectedFarm.name} , {selectedFarm.farmArea} Ha | Sowing Date: {moment(selectedFarm.sowingDate).format("Do, MMM YYYY")}, Crop: {selectedFarm.cropData} </Typography>
                              
                              <Typography variant="body1" sx={{ color: 'white',cursor:'pointer' }} onClick={() => window.open(`https://www.google.com/maps/dir/?api=1&origin=&destination=${selectedFarm.center}`, '_blank')}
                              >Get Directions: <RampLeft sx={{ transform: 'rotate(90deg)' }} /></Typography>

                            </Box>

                          
                            {
                              SENTINELURL!=null ? <>
                                <Paper
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'nowrap',
                                    listStyle: 'none', flexDirection: 'row', backgroundColor: '#28282b', color: '#fff', boxShadow: 'none',
                                    p: 0.5,
                                    m: 0,
                                  }}
                                  component="ul"
                                >
                                  {chipData.map((data) => {


                                    return (
                                      <ListItem key={data.key}>
                                        <Chip
                                          variant="outlined"
                                          label={data.label}
                                          size="large"
                                          style={{
                                            color: 'white',
                                            borderColor: selectedChip === data.key ? '#f9b64d' : 'white',
                                          }}
                                          onClick={async () => {
                                          
                                            setSelectedChip(data.key)
                                            setShowPreviewLoader(true)
                                            //console.log(SENTINELURL)
                                            let parts; 
                                            if (selectedIndex == MSAVI || selectedIndex == "MSAVI") {
                                              parts = SENTINELURL.split(MSAVI);
                                            } else {
                                              parts = SENTINELURL.split(selectedIndex);
                                            }
                                           
                                            const colorModule = await import(`../utils/constants`); 
                                            const oldColor = colorModule[selectedIndex==MSAVI?"MSAVI":selectedIndex + "ColorMap"]; 
                                            const newColor = colorModule[data.label + "ColorMap"];  
                                            
                                            const indexLevel = colorModule[selectedIndex==MSAVI?"MSAVI":selectedIndex + "Level"];
                                            const newIndexLevel =  colorModule[data.label + "Level"]; 
                                          //  console.log(oldColor,newColor,indexLevel,newIndexLevel,parts)
                                            const Newlevels = parts[1].split("&COLORMAP="+oldColor+"&MIN_MAX="+indexLevel).join("&COLORMAP="+newColor+"&MIN_MAX="+newIndexLevel);
                                            if (data.label == "MSAVI") {
         
                                              setSENTINELURL(parts[0] + MSAVI + Newlevels);
                                            } else {
                                                
                                              setSENTINELURL(parts[0] + data.label + Newlevels);
                                           }
                                           setShowPreviewLoader(false)
                                           setSelectedIndex(data.label)

                                          }}
                                        />
                                      </ListItem>
                                    );
                                  })}
                                </Paper>
                                <Box sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  flexWrap: 'nowrap', alignItems: 'center',
                                  listStyle: 'none', flexDirection: 'row', backgroundColor: '#28282b', color: '#fff', boxShadow: 'none',
                                  p: 0.5,
                                  m: 0,
                                }} >

                                  <div style={{ textAlign: 'center', width: '250px', marginBottom: 2 }}>
                                    {/* <img
                                      src={SENTINELURL}
                                      alt={`Image Date: ${moment(requestData.date).format("Do, MMM YYYY")} | Cloud coverage: ${requestData.cloud} %`}
                                      style={{ width: '200px', height: '200px', }}
                                    /> */}

                                    <ReactImageMagnify {...{
                                      enlargedImageContainerStyle:{ 
                                        left:'none',
                                        right: '100%',
                                        marginLeft: '10px',
                                        border:'none'
                                        },
                                      smallImage: {
                                        alt: `Image Date: ${moment(requestData.date).format("Do, MMM YYYY")} | Cloud coverage: ${requestData.cloud} %`,
                                        isFluidWidth: true,
                                        src: SENTINELURL,
                                        srcSet: SENTINELURL,
                                        sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px'
                                      }, 
                                      largeImage: {
                                        src: SENTINELURL,
                                        width: 500,
                                        height: 500,
                                      }
                                    }} />

                                    <div style={{ color: 'white', marginTop: 10 }}> Source: Sentinel - 2 <br /> Image Date: {moment(requestData.date).format("Do, MMM YYYY")} <br />   Cloud coverage: {requestData.cloud} % <br /> Average Index Value: {requestData.average != null ? requestData.average.toFixed(4) : ''}</div>
                                  </div>

                                  <LegendComponent selectedIndex={selectedIndex} />


                                </Box>
                                <Button
                                  onClick={() => {
                                    geoJsonClick({
                                        type: "FeatureCollection",
                                        features: [
                                            {
                                                type: "Feature",
                                                properties: {},
                                                geometry: JSON.parse(selectedFarm.farmGeoJson).geometry,
                                            },
                                        ],
                                    });
                                }}
                                  variant="contained"
                                  style={{
                                    padding: "10px",
                                    marginTop: 12,
                                    border: "none",
                                    background: '#f9b64d',
                                    color: 'white',
                                    fontWeight: 'bold',
                                  }}
                                >

                                  Check Detailed View

                                </Button>
                              </> : <>
                              
                                  <Oval
                                    visible={true}
                                    height="50"
                                    radius="50"
                                    width="50"
                                    color="#fff"
                                    secondaryColor="#cccc"
                                    ariaLabel="revolving-dot-loading"
                                    wrapperClass=""
                                    wrapperStyle={{
                                    marginTop:10
                                  }}
                                  /> 
                              
                              </>
                            }
                          
                          
                        

                          </>
                       
                      ) : null}
 

              </Grid>
              
            </Grid>
          </ThemeProvider>
        </Box>
        
        )}
              <ToastContainer />
      </Card>

    </Grow>

//         <Grid container style={floatingLayerStyles} id="FarmsContainer">
//             <Grid
//                 item
//                 xs={12}
//                 lg={12}
//                 md={12}
//                 sm={12}
//                 style={{
//                     marginTop: "10px",
//                     backgroundColor: "#28282B",
//                 }}
//             >
//                 <div
//                     className="topbar"
//                     style={{ marginBottom: "10px", padding: "15px" }}
//                 >
//                     <CloseIcon
//                         style={{ color: "white", fontSize: "25px", cursor: "pointer" }}
//                         onClick={onClose}
//                     />
//                 </div>
//                 {formData.length > 0 ? (
//   <div style={{ position: "relative",overflow: "auto", }}>
//     {formData.map((item, index) => {
//       const farmDetails = JSON.parse(item.farmDetails);
//       const isVisible = visibleDetails[farmDetails.id];
//       const isLoading = loadingDetails[farmDetails.id];
      
//       // Ensure entire return statement is properly enclosed
//       return item.farmGeoJson ? (
//         <div key={index} style={{ borderTop: "1px #11151B" }}>
//           <Box
//             display="flex"
//             alignItems="center"
//             sx={{
//               borderTop: "1px solid #11151B",
//               padding: "10px 15px",
//               gap: "15px",
//             }}
//           >
//             <Box>
//               <div onClick={() => 
//                 {
//                     geoJsonClick(  {
//                         type: "FeatureCollection",
//                         features: [
//                           {
                            
//                             type: "Feature",
//                             properties: {},
//                             geometry:JSON.parse(item.farmGeoJson).geometry, 
//                           },
//                         ],
//                       }  )
 
//                 }
//               }>
//                 <GeoJsonSvg farmGeoJson={JSON.parse(item.farmGeoJson)} />
//               </div>
//             </Box>
//             <Box width="100%">
//               <Box
//                 display="flex"
//                 alignItems="center"
//                 justifyContent="space-between"
//                 sx={{ gap: "15px" }}
//               >
//                 <Box>
//                   <Typography
//                     variant="body2"
//                     color={"white"}
//                     style={{ fontWeight: "bold", fontSize: "14px" }}
//                   >
//                     Farm ID: {farmDetails.id}
//                   </Typography>
//                   <Typography
//                     variant="body2"
//                     color={"white"}
//                     style={{ fontWeight: "bold", fontSize: "14px" }}
//                   >
//                     Area: {farmDetails.area}
//                   </Typography>
//                 </Box>
//                 <Box>
//                   <Button
//                     variant="text"
//                     onClick={() => fetchDetailedInformation(farmDetails.id)}
//                     style={{
//                       color: "white",
//                       minWidth: "24px !important",
//                     }}
//                     disabled={isLoading}
//                   >
//                     {isLoading ? (
//                       <Oval
//                         visible={true}
//                         height="50"
//                         radius="50"
//                         width={30}
//                         color="#fff"
//                         secondaryColor="#cccc"
//                         ariaLabel="revolving-dot-loading"
//                         wrapperStyle={{
//                           position: "absolute",
//                           top: "50%",
//                           left: "50%",
//                           transform: "translate(-50%, -50%)",
//                           zIndex: 99999,
//                         }}
//                         wrapperClass=""
//                       />
//                     ) : isVisible ? (
//                       <KeyboardArrowUpIcon style={{ color: "white" }} />
//                     ) : (
//                       <KeyboardArrowDownIcon style={{ color: "white" }} />
//                     )}
//                   </Button>
//                 </Box>
//               </Box>
//               {isVisible && (
//                 <div style={{ marginTop: "10px" }}>
//                   {isLoading ? (
//                     <Typography variant="body2" color="inherit">
//                       Loading farm details...
//                     </Typography>
//                   ) : detailedInfo[farmDetails.id] ? (
//                     <>
//                       <Typography variant="body2" color="white">
//                         <strong>Farm Name:</strong>{" "}
//                         {detailedInfo[farmDetails.id].properties.name || "N/A"}
//                       </Typography>
//                       <Typography variant="body2" color="white">
//                         <strong>Crop:</strong>{" "}
//                         {detailedInfo[farmDetails.id].properties.crop_type || "N/A"}
//                       </Typography>
//                     </>
//                   ) : (
//                     <Typography variant="body2" color="inherit">
//                       No details available for this farm.
//                     </Typography>
//                   )}
//                 </div>
//               )}
//             </Box>
//           </Box>
//         </div>
//       ) : null;  // Ensure nothing is returned if farmGeoJson is falsy
//     })}
//   </div>
// ) : (
//   <Typography variant="body2" color="inherit">
//     <Oval
//       visible={true}
//       height="50"
//       radius="50"
//       width={50}  // Use the width prop here
//       color="#fff"
//       secondaryColor="#cccc"
//       ariaLabel="revolving-dot-loading"
//       wrapperStyle={{
//         position: "absolute",
//         top: "50%",
//         left: "50%",
//         transform: "translate(-50%, -50%)",
//         zIndex: 99999,
//       }}
//       wrapperClass=""
//     />
//   </Typography>
// )}

//                 {/* Global Loader for loading more */}
//                 {loadingMore && (
//                     <Box
//                         position="fixed"
//                         top="0"
//                         right="0"
//                         width="350px"
//                         height="100vh"
//                         display="flex"
//                         alignItems="center"
//                         justifyContent="center"
//                         bgcolor="rgba(0, 0, 0, 0.6)"
//                         zIndex="1000"
//                     >
//                         <Oval
//                             visible={true}
//                             height="50"
//                             radius="50"
//                             width={50}  // Use the width prop here
//                             color="#fff"
//                             secondaryColor="#cccc"
//                             ariaLabel="revolving-dot-loading"
//                             wrapperStyle={{
//                                 position: "absolute",
//                                 top: "50%",
//                                 left: "50%",
//                                 transform: "translate(-50%, -50%)",
//                                 zIndex: 99999,
//                             }}
//                             wrapperClass=""
//                         />
//                     </Box>
//                 )}
//             </Grid>
//         </Grid>
    );
};

export default Farms;