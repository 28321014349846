import Toolbar from "@mui/material/Toolbar";
import { Box, Typography, Grid } from "@mui/material";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";

import { useWindowSize } from "@uidotdev/usehooks";
import FinalCard from "./FinalCard";
import SmallerCard from "./SmallerCard";
import CustomCarousel from "./CustomCarousel";
import PixelCard from "./PixelCard";
import service_1 from "../../public/images/service_1.png";
import service_2 from "../../public/images/service_2.png";
import service_3 from "../../public/images/service_3.png";
import Goals_IMG from "../../public/images/Sustainable-Development-Goals.svg";

export default function SDGComponent() {
    const windowSize = useWindowSize();
    return (
        <div id="sdg">
            <Grid container component="main">



                <Grid item xs={12} style={{ backgroundColor: "#181f26" }}>
                    <Box
                        style={{
                            position: "relative",
                            borderRadius: "50px",
                            margin: "0 auto",
                            marginTop: "40px",
                            marginBottom: "40px",
                            width:
                                windowSize.width < 992
                                    ? "95%"
                                    : windowSize.width < 1025
                                        ? "85%"
                                        : windowSize.width < 1960
                                            ? "65%"
                                            : "50%",
                        }}
                    >
                        <Typography
                            variant="h5"
                            component="div"
                            color={"white"}
                            style={{ fontWeight: "bold", color: "#f27e30", marginBottom: 40 }}
                        >
                            <strong>Sustainable Development Goals </strong>
                        </Typography>
                        <Typography
                            variant="body1"
                            component="div"
                            textAlign={"justify"}
                            mb={5}
                            style={{ color: "rgb(193, 207, 219)" }}
                        >
                           At Earth Scan Systems (ESS), we are committed to advancing the United Nations Sustainable Development Goals (SDGs) through innovative geospatial and remote sensing technologies. Our mission aligns closely with several key SDGs by providing data-driven solutions that enable sustainable environmental management and informed decision-making.
                        </Typography>
                        <Box style={{ width: windowSize.width < 992 ? "60%" : "70%", margin: '0 auto' }}>
                            <img
                                src={Goals_IMG}
                                width="100%"
                                alt="Sustainable Development Goals"
                            />
                        </Box>

                    </Box>
                </Grid>


            </Grid>
        </div>
    );
}
  